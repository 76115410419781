
.form-textarea{
  width: 100%;
  height: auto;
  resize: none;
}
.home .container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20%;
  max-width: 81.875em;
  padding: 0 1em;
  width: 100%;      
}
.home .inner {
  margin: 0 auto;
  max-width: 34.375em;
  text-align: center;
}
.loader {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;  
  position: absolute;
  top: 30%;
  left: 50%;
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.intro {
  margin-top: 2%;
}
.loginBtn {
  background-color: #0082f9;
  border: 1px solid #0082f9;
  border-radius: 1.25rem;
  color: white;
  display: inline-block;
  font-size: 0.875em;
  font-weight: 300;
  height: 2.5rem;
  line-height: 2.5em;
  outline: 0;
  padding: 0 2.2rem;
  position: relative;
  transition: background-color 0.4s ease, border-color 0.4s ease,
    color 0.4s ease;
  width: 100%;
}
.loginBtn i {
  font-size: 1.3em;
  left: 2.2rem;
  pointer-events: none;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
@media screen and (min-width: 576px) {
  .btn--lg {
    border-radius: 1.875rem;
    font-size: 1em;
    height: 3.75rem;
    line-height: 3.75em;
    min-width: 18.75rem;
  }
}
.loginBtn:hover {
  background-color: #003f7a;
  border-color: #003f7a;
}
.loginBtn {
  width: auto;
}
footer p {
  color: #84cff0;
  font-size: 0.9em;  
}
.imgContainer {
  align-items: center;
}
.logo{
  padding-top: 2rem;
}

.circle {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  margin-top: 0.65rem;
}

.disable-background {
  z-index:1050;
  width:100%;
  height:100%;
  position:relative;
  top:0;
  left:0;
  color: gray;
  opacity: .4; 
  pointer-events: none;
}
.signin-text {
  padding: 2rem 0 2rem;
}

.Toastify__toast--error {
  background-color: #B83A2C;
  opacity: 0.95;
}